<template>
  <div class="header-settings">
    <div class="header-settings__btn btn-style" v-on:click="show = !show">
      <SettingsIco/>
    </div>

    <transition name="fade">
      <div class="header-settings__content" v-if="show" v-bind:class="{show: show}">

        <div class="header-settings__close brown-close" v-on:click="show = !show"></div>

        <div class="header-settings__title admin-edit-item">
          <span class="admin-edit" @click="editTranslate(['common_settings'])"></span>
          {{ $t('common_settings.localization_value.value') }}
        </div>

        <div class="header-settings__lang">
          <div class="header-settings__lang-name admin-edit-item">
            <span class="admin-edit" @click="editTranslate(['header_systemLanguage'])"></span>
            {{ $t('header_systemLanguage.localization_value.value') }}
          </div>

          <div class="header-settings__lang-items">
            <div class="header-settings__lang-item"
                 v-for="(item, index) in $store.getters.GET_LANGUAGES" :key="index"
                 v-bind:class="{active: item.locale === $store.getters.GET_LANG}"
                 @click="changeLang(item)"
            >
              {{item.locale}}
            </div>
          </div>
        </div>
        <!--$store.getters.getCurrentUserRights.additionalPermissions &&-->
        <!--$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.IMPERSONALIZATION_MODE]-->
        <!--{{downloadPermissions}}-->

        <div class="header-settings__mode admin-edit-item"
             v-if="_.has(currentPermissions, PERMISSIONS.IMPERSONALIZATION_MODE) || getParentUserId()">
          <span class="admin-edit" @click="editTranslate(['header_adminMode'])"></span>

          <SwitchCheckbox :label="$t('header_adminMode.localization_value.value')"
                          :value="adminMode"
                          v-model="adminMode"
                          @input="changeMode"
          />
        </div>


        <div class="header-settings__logout-wrap">
          <div class="header-settings__logout-ico btn-style"
               @click="goToProfile"
          >
            <div class="header-settings__logout-ico-i">
              <UsersIco/>
            </div>

            <div class="header-settings__logout-user-name">
              {{$store.getters.getUserProfile.user_personal_contact.name}}
            </div>
          </div>

          <div class="header-settings__logout admin-edit-item">
            <span class="admin-edit" @click="editTranslate(['header_logOut'])"></span>
            <span @click="$emit('logout')">
              {{ $t('header_logOut.localization_value.value') }}
            </span>
          </div>
        </div>

      </div>
    </transition>

  </div>
</template>

<script>
import SettingsIco from '../../../../../../../public/img/header/settings.svg?inline'
// import {loadLanguageAsync} from "../../../../../../language/language";
import SwitchCheckbox from "../../../../../UI/checkboxes/SwitchCheckbox/SwitchCheckbox";

import {TRANSLATE_MODE_KEY, TRANSLATE_MODE_VARIABLE} from '../../../../../../staticData/staticVariables'
import UsersIco from '../../../../../../../public/img/sidebar-group/UsersIco.svg?inline'
import {checkAccess} from "../../../../../../mixins/userCredentionalMixins/userCredentional";
import {loadLanguageAsync} from "../../../../../../language/language";



export default {
  name: "HeaderAdminSettings",

  mixins: [checkAccess],

  data() {
    return {
      show: false,
      adminMode: false,
      userId:'',
    }
  },

  components: {
    SettingsIco,
    SwitchCheckbox,
    UsersIco,
  },

  // computed: {
  //   downloadPermissions(){
  //     return true
  //   }
  // },

  watch: {
    loadUserRoleAndAuthorizedData: function (val) {
      if(val){
        let localLang = localStorage.getItem('translationLang')
        let settingsLanguage = this.$store.getters.GET_LANGUAGES.find(item => {
          return item.id === this.$store.getters.GET_COMMON_AUTHORIZED.user.language_id
        })

        if (!this.checkImpersonate() && settingsLanguage && settingsLanguage.locale !== localLang) {
          loadLanguageAsync(settingsLanguage.locale).then(() => {
            this.$store.commit('SET_LANG', settingsLanguage.locale)
          })
          this.initDateMomentLang(settingsLanguage.locale)
        }

      }
    },
  },

  mounted() {
    document.addEventListener('click', (e) => {
      if (document.querySelector('.header-settings__content.show') !== null) {
        if (e.target.closest('.header-settings') === null) {
          this.show = false;
        }
      }
    })

    this.adminMode = localStorage.getItem(TRANSLATE_MODE_KEY) === TRANSLATE_MODE_VARIABLE

  },

  methods: {
    changeMode(val) {
      if (val) {
        localStorage.setItem(TRANSLATE_MODE_KEY, TRANSLATE_MODE_VARIABLE)
        this.$store.commit('SET_TRANSLATION_MODE', true)
      } else {
        localStorage.removeItem(TRANSLATE_MODE_KEY)
        this.$store.commit('SET_TRANSLATION_MODE', false)
      }
    },

    getParentUserId(){
      return localStorage.getItem('parentUserId') !== null
    },


    initDateMomentLang(lang){
      if(lang === 'ua'){
        this.$moment.locale('uk')
      } else {
        this.$moment.locale(lang)
      }
    },

    // changeLang(item) {
    //   loadLanguageAsync(item.locale).then(() => {
    //     this.$store.commit('SET_LANG', item.locale)
    //     this.$store.dispatch('changeServerLocale', item.id)
    //   })
    // },
  },

}
</script>

<style lang="scss">
@import "../../../../../../scss/colors";
@import "../../../../../../scss/mixins/mixins";

.header-settings {
  position: relative;
  margin-left: 20px;

  display: flex;
  justify-content: space-between;

  &:hover {
    .header-settings__btn {
      transform: translateY(-3px);
    }
  }

  &__btn {
    display: flex;
    transition: 0.3s;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__close {
    background: url("../../../../../../assets/img/common/close-brown.svg") center center no-repeat;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    width: 280px;
    right: 0;
    top: -10px;
    background: $white;
    border: 1px solid $mainBg;
    box-shadow: 0 4px 7px #ECE5DC;
    padding: 30px 0;
    z-index: 20;

    @include for-680{
      right: -55px;
      z-index: 101;
    }
  }

  &__title {
    padding: 0 30px;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: $brown;
  }

  &__lang {
    padding: 0 30px;
    margin-top: 25px;
  }

  &__mode {
    padding: 0 30px;
    margin-top: 25px;
  }

  &__lang-name {
    font-size: 14px;
    line-height: 21px;
    color: #232323;
  }

  &__lang-items {
    display: flex;
    margin-top: 8px;
  }

  &__lang-item {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #ddd6ce;
    cursor: pointer;
    transition: 0.3s;
    text-transform: uppercase;

    &:hover,
    &.active {
      color: $accent;
    }

    &:nth-child(n+2) {
      position: relative;
      margin-left: 10px;
      padding-left: 10px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        display: block;
        width: 1px;
        height: 12px;
        background: $borderBrown;
        opacity: 0.2;
      }
    }
  }

  &__logout-wrap{
    padding: 0 30px;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  &__logout-ico{
    display: flex;
  }

  &__logout-ico-i{
    width: 17px;
    height: 20px;
  }

  &__logout-user-name{
    padding: 0 15px 0 10px;
    font-size: 11px;
    color: $brown;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: fit-content;
  }

  &__logout {
    /*margin-top: 30px;*/
    /*padding: 0 30px;*/
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $red;
    display: inline-flex;
    cursor: pointer;
    white-space: nowrap;
  }

}
  .header-admin-user-select{
    .vs__selected{
      font-size: 12px;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 34px;
    }

    .vs__selected {
      padding: 0;
      margin: 0;
      top: 0px;
    }
  }


</style>
