<template>
  <div class="card-detail-left"
      :class="{ 'blockForAdmin' : blockForAdmin === true, 'easy-order': isUserHasEasyOrder }"
  >
    <div class="card-detail-left__bg card-detail-left__bg--notifications" v-if="backgroundImage == 'notifications'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--shops" v-else-if="backgroundImage == 'shops'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--consolidation" v-else-if="backgroundImage == 'consolidation'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--balance" v-else-if="backgroundImage == 'balance'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--cus-payments" v-else-if="backgroundImage == 'cus-payments'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--listing" v-else-if="backgroundImage == 'listing'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--subuser" v-else-if="backgroundImage == 'subuser'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--product-admin" v-else-if="backgroundImage == 'product-admin'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--orders" v-else-if="backgroundImage == 'orders'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--subadmin" v-else-if="backgroundImage == 'subadmin'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--store" v-else-if="backgroundImage == 'store'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--rank" v-else-if="backgroundImage == 'rank'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--accounts" v-else-if="backgroundImage == 'accounts'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--customs-info" v-else-if="backgroundImage == 'customs-info'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--shipments" v-else-if="backgroundImage == 'shipments'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--order-consolidation" v-else-if="backgroundImage == 'order-consolidation'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--expense" v-else-if="backgroundImage == 'expense'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--process-report" v-else-if="backgroundImage == 'process-report'"></div>
    <div class="card-detail-left__bg" v-else-if="backgroundImage == 'no-bg'"></div>
    <div class="card-detail-left__bg card-detail-left__bg--home" v-else>
      <!--<OrderIdBlockBg/>-->
    </div>

    <div class="card-detail-left__content" v-bind:class="{'mobile-full-width': mobileFullWidth}">

      <div class="card-detail-left__name">
        {{name}}
      </div>

      <div class="card-detail-left__id">
        {{value}}
      </div>

      <div class="card-detail-left__caption" v-if="$slots.label">
        <slot name="label">

        </slot>
      </div>

      <slot name="body">

      </slot>

      <slot name="button">

      </slot>

      <slot name="footer">

      </slot>

    </div>
  </div>
</template>

<script>
  // import OrderIdBlockBg from '../../../../../../../public/img/orders-group/order-id-block-bg.svg?inline'

  export default {
    name: "CardLeftBlock",
    components: {
      // OrderIdBlockBg,
    },

    props: [
      'name',
      'value',
      'backgroundImage',
      'blockForAdmin',
      'mobileFullWidth',
    ],

    mounted() {

    }

  }

</script>

<style lang="scss">
  @import "./CardLeftBlock";


  .card-detail-left__content.mobile-full-width {
    @include for-1200 {
      max-width: 100%;
    }
  }
</style>
