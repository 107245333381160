import {PERMISSIONS} from "../../../../staticData/permissionsStatic";
import {RETURN_GOODS_TYPES} from "@/staticData/staticVariables";


export function GetMenuList() {

}

GetMenuList.prototype = {
  EASY_ORDERS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.easyOrders,
          translation: 'menu_easyOrdersShipments',
          hiddenOptionArr: [
            'isEasyOrder'
          ],
          // permissionLinkName: PERMISSIONS.ORDER_EXPRESS_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.ordersConsolidation,
        //   translation: 'menu_consolidation',
        //   permissionLinkName: PERMISSIONS.CONSOLIDATION_GENERAL,
        //   hiddenOptionArr: [
        //     'isEasyOrder'
        //   ],
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersConsolidation,
          translation: 'menu_shipmentToWarehouse',
          hiddenOptionArr: [
            'isEasyOrder'
          ],
          permissionLinkName: PERMISSIONS.ORDER_INBOUND_GENERAL,
        },
      ]
    }
  },
  ORDER_DISPATCHES: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.expressOrders,
          translation: 'menu_express',
          permissionLinkName: PERMISSIONS.ORDER_EXPRESS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersConsolidation,
          translation: 'menu_consolidation',
          permissionLinkName: PERMISSIONS.CONSOLIDATION_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersFBM,
          translation: 'menu_createOrders',
          permissionLinkName: PERMISSIONS.ORDER_FBM_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersFBA,
          translation: 'menu_ordersFBA',
          permissionLinkName: PERMISSIONS.ORDER_FBA_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersShipmentWarehouse,
          translation: 'menu_shipmentToWarehouse',
          permissionLinkName: PERMISSIONS.ORDER_INBOUND_GENERAL,
        },
        {
          linkPath: that.$store.getters.GET_PATHS.ordersMeestReturns,
          translation: 'menu_problemsMeestReturns',
          permissionLinkName: PERMISSIONS.ORDER_MEEST_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ukrposhta,
          translation: 'menu_ukrposhta',
          hiddenOptionArr: [
            'isUkrainian'
          ],
          permissionLinkName: PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.belposhta,
        //   translation: 'menu_belposhta',
        //   hiddenOptionArr: [
        //     'isBelarus'
        //   ],
        //   permissionLinkName: PERMISSIONS.ORDER_INTERNAL_BELPOSHTA,
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cdek,
        //   translation: 'menu_cdek'
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.novaPoshta,
        //   translation: 'menu_novaPoshta'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.importOrders,
          translation: 'menu_importOrders',
          permissionLinkName: PERMISSIONS.ORDER_EXTERNAL_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.products,
        //   translation: 'menu_returns',
        // },

      ]
    }
  },

  FINANCE: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.cusPayments,
          translation: 'menu_customersPayments',
          permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.IE559Report,
          translation: 'menu_IE559Report',
          // permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_GENERAL,
          hiddenOptionArr: [
            'isPoland'
          ],
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeUnidentifiedPayments,
          translation: 'menu_unidentifiedPayments',
          permissionLinkName: PERMISSIONS.UNDEFINED_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeUkrTreasuresLink,
          translation: 'menu_paymentListings',
          permissionLinkName: PERMISSIONS.PAYMENT_LISTING_GENERAL,
          hiddenOptionArr: 'paymentListings',
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.products,
        //   translation: 'menu_etsyListings'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeRequestFunds,
          translation: 'menu_requestFunds',
          permissionLinkName: PERMISSIONS.BALANCE_OUTPUT_GENERAL,
          hiddenOptionArr: [
            'isEasyOrder'
          ],
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeRequestReturn,
          translation: 'menu_requestReturn',
          permissionLinkName: PERMISSIONS.REFUNDS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeInvoices,
          translation: 'menu_invoices',
          permissionLinkName: PERMISSIONS.INVOICES_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeMyPayments,
          translation: 'menu_myPayments',
          permissionLinkName: PERMISSIONS.MY_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeNewPaymentsPayoneer,
          translation: 'menu_financeNewPaymentsPayoneer',
          permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER,
          hiddenOptionArr: [
            'isEasyOrder'
          ],
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.products,
        //   translation: 'menu_topUpBalance'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financePayPalCases,
          translation: 'menu_payPalCases',
          permissionLinkName: PERMISSIONS.PAYPAL_CASE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.forbiddenGoods,
          translation: 'menu_forbiddenGoods',
          count: that.$store.getters.GET_COMMON_AUTHORIZED?.forbidden_payment_transaction_count,
          permissionLinkName: PERMISSIONS.FORBIDDEN_PAYMENT_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.financeUkrTreasuresLink,
        //   translation: 'breadcrumbs_UkrainianTreasures'
        // },

      ]
    }
  },

  WAREHOUSE: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.products,
          translation: 'menu_products',
          permissionLinkName: PERMISSIONS.PRODUCT_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.warehouseProductsReport,
        //   translation: 'menu_productsReport'
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_movingGoods'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.warehouseDisposalOrders,
          translation: 'menu_writeOffProduct',
          permissionLinkName: PERMISSIONS.ORDER_DISPOSAL_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.warehouseProductsReport,
          translation: 'menu_productsReport',
          permissionLinkName: PERMISSIONS.REPORT_PRODUCTS_GENERAL,
        },
      ]
    }
  },

  PROBLEMS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.problemsParcels,
          translation: 'menu_parcelsDays',
          permissionLinkName: PERMISSIONS.PARCELS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.problemsFillClaim,
          translation: 'menu_fileAClaim',
          hiddenOptionArr: [
            'isEasyOrder'
          ],
          permissionLinkName: PERMISSIONS.CLAIM_GENERAL,
        },
        {
          parent: false,
          linkPath: '',
          translation: 'menu_returnPackages',
          hasChild: true,
          hiddenOptionArr: [
            'isEasyOrder'
          ],
          permissionLinkName: PERMISSIONS.RETURN_PARCEL_GENERAL,
          child: [
            {
              linkOtherPath: that.$store.getters.GET_PATHS.problemsReturnGoodsUrl,
              linkPath: that.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.MY_RETURNS,
              translation: 'menu_myReturns'
            },
            {
              linkOtherPath: that.$store.getters.GET_PATHS.problemsReturnGoodsUrl,
              linkPath: that.$store.getters.GET_PATHS.problemsUnidentifiedReturns,
              translation: 'menu_unidentifiedReturns',
            },
          ]
        },
      ]
    }
  },

  INCOME_EXPRESS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.incomeExpensesProfitReport,
          translation: 'menu_profitStatement',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.incomeExpensesShippingReport,
          translation: 'menu_shippingReport'
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.expenses,
          translation: 'menu_expenses',
          permissionLinkName: PERMISSIONS.EXPENSE_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_payee'
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_costType'
        // },
      ]
    }
  },

  HEARTYSAN: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanChoice,
          translation: 'menu_heartysanChoice',
          hiddenOptionArr: 'heartysanChoice',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanManufacturerListings,
          translation: 'menu_heartysanManufacturerListings',
          hiddenOptionArr: 'heartysanManufacturer',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanSellerListings,
          translation: 'menu_heartysanSellerListings',
          hiddenOptionArr: 'heartysanSeller',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanChat,
          translation: 'menu_heartysanChat',
          count: that.$store.getters.GET_COMMON_AUTHORIZED.count_hearty_chat_new_messages,
          // hiddenOptionArr: 'heartysanSeller',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanEtsyListings,
          translation: 'menu_heartysanEtsyListings',
          hiddenOptionArr: 'heartysanSeller',
        },
      ]
    }
  },

  MARKETPLACE: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.marketplaceProducts,
          translation: 'menu_marketplaceProducts',
          permissionLinkName: PERMISSIONS.UKRAINIAN_TREASURES_ACCESS,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.marketplaceReviews,
          translation: 'menu_marketplaceReviews',
          permissionLinkName: PERMISSIONS.UKRAINIAN_TREASURES_REVIEWS,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.marketplaceRates,
          translation: 'menu_marketplaceRates',
          permissionLinkName: PERMISSIONS.UKRAINIAN_TREASURES_SHIPPING_RATE,
        },
      ]
    }
  },

  // REPORTS: (that) => {
  //   return {
  //     links: [
  //     ]
  //   }
  // },

  SETTINGS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.accounts,
          translation: 'menu_personalAccount',
          permissionLinkName: PERMISSIONS.ACCOUNT_GENERAL,
          linkFunc: 'goToPersonalAccount',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsShops,
          translation: 'menu_shops',
          permissionLinkName: PERMISSIONS.SHOPS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsEtsyListings,
          translation: 'menu_productImportFromEtsy',
          permissionLinkName: PERMISSIONS.LISTING_DOWNLOAD_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsEtsyTransfer,
          translation: 'menu_transferEtsy',
          permissionLinkName: PERMISSIONS.LISTING_DOWNLOAD_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsManageUsersLink,
          translation: 'menu_subuser',
          permissionLinkName: PERMISSIONS.USER_MANAGE_ACCESS_SUB_USER,
          hiddenOptionArr: 'userManage',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsProfilesLink,
          translation: 'menu_profiles',
          permissionLinkName: PERMISSIONS.PROFILE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.downloadPlugins,
          translation: 'menu_modules',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsCustomsInformation,
          translation: 'menu_customsInformation',
          // permissionLinkName: PERMISSIONS.DELIVERY_COUNTRY_CUSTOMS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsInventory,
          translation: 'menu_mainSettingsInventory',
          permissionLinkName: PERMISSIONS.INVENTORY_GENERAL,
        },
      ]
    }
  },

  STATISTICS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.statisticsDeliveryStatistics,
          translation: 'menu_deliveryStatistics',
          permissionLinkName: PERMISSIONS.DELIVERY_STATISTIC_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.graphicsStatistic,
          translation: 'menu_graphicsStatistic',
          permissionLinkName: PERMISSIONS.USER_BALANCE_GRAPHIC_STATISTIC,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.statisticsEtsyRank,
        //   translation: 'menu_etsyRank'
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_googleAnalytics'
        // },
      ]
    }
  },

  FINANCE_ORDER_EASY: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeRequestFunds,
          translation: 'menu_requestFunds',
          // permissionLinkName: PERMISSIONS.BALANCE_OUTPUT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeNewPaymentsPayoneer,
          translation: 'menu_financeNewPaymentsPayoneer',
          // permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER,
        },
      ]
    }
  },

}
