
import {routerPaths} from '../../../router/routerPaths'
import permissions from "../../../router/middleware/permissions";
import {PERMISSIONS} from "../../../staticData/permissionsStatic";

const EasyOrdersTable = () => import(/* webpackChunkName: "group-easy" */ './components/EasyOrdersTable/EasyOrdersTable.vue')
const EasyOrdersCreation = () => import(/* webpackChunkName: "group-easy" */ './components/EasyOrdersCreation/EasyOrdersCreation.vue')
const EasyOrdersEdit = () => import(/* webpackChunkName: "group-easy" */ './components/EasyOrdersEdit/EasyOrdersEdit.vue')
const EasyOrdersShow = () => import(/* webpackChunkName: "group-easy" */ './components/EasyOrdersShow/EasyOrdersShow.vue')
const moduleKey = 'easy-orders'

export const EasyOrdersRoutes = [
  {
    path: routerPaths.easyOrders,
    component: EasyOrdersTable,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'easyOrders'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_easyOrdersShipments',
            link: routerPaths.easyOrders,
          },
          'easyOrders': {
            name: 'breadcrumbs_EasyOrders',
            link: routerPaths.easyOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      moduleKeyChapters: true,
      pageTitle: 'breadcrumbs_EasyOrders'
    },
  },

  {
    path: routerPaths.easyOrderCreationID,
    component: EasyOrdersCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'easyOrders',
          'easyOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_easyOrdersShipments',
            link: routerPaths.easyOrders,
          },
          'easyOrders': {
            name: 'breadcrumbs_EasyOrders',
            link: routerPaths.easyOrders,
          },
          'easyOrderCreation': {
            name: 'breadcrumbs_NewEasyOrders',
            link: routerPaths.easyOrderCreationID,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewEasyOrders'
    },
  },

  {
    path: routerPaths.easyOrderCreationIDTransaction,
    component: EasyOrdersCreation,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'easyOrders',
          'easyOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_easyOrdersShipments',
            link: routerPaths.easyOrders,
          },
          'easyOrders': {
            name: 'breadcrumbs_EasyOrders',
            link: routerPaths.easyOrders,
          },
          'easyOrderCreation': {
            name: 'breadcrumbs_NewEasyOrders',
            link: routerPaths.easyOrderCreationID,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_NewEasyOrders'
    },
  },

  {
    path: routerPaths.easyOrderEditItem,
    component: EasyOrdersEdit,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'easyOrders',
          'easyOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_easyOrdersShipments',
            link: routerPaths.easyOrders,
          },
          'easyOrders': {
            name: 'breadcrumbs_EasyOrders',
            link: routerPaths.easyOrders,
          },
          'easyOrderCreation': {
            name: 'breadcrumbs_EditEasyOrders',
            link: routerPaths.easyOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_EditEasyOrders'
    },
  },

  {
    path: routerPaths.easyOrderShowItem,
    component: EasyOrdersShow,
    meta: {
      breadcrumbs: {
        key: [
          'ordersAndDispatches',
          'easyOrders',
          'easyOrderCreation'
        ],
        items: {
          'ordersAndDispatches': {
            name: 'menu_easyOrdersShipments',
            link: routerPaths.easyOrders,
          },
          'easyOrders': {
            name: 'breadcrumbs_EasyOrders',
            link: routerPaths.easyOrders,
          },
          'easyOrderCreation': {
            name: 'breadcrumbs_ViewEasyOrders',
            link: routerPaths.easyOrders,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.ORDER_EXPRESS_GENERAL],

      moduleKey: moduleKey,
      pageTitle: 'breadcrumbs_ViewEasyOrders'
    },
  },
]
