import {API} from "../../axios/axiosMainUrl";

const FBMPrefix = '/orders'
const FBMPrefixMain = 'order-fbm'
const adminPrefix = 'admin'

export const ordersFBM = {
  state: {
    ordersFBM: [],
    ordersFBMItem: {},
    ordersFBMCommonList: [],
    ordersFBMBtn: false,
    ordersFBMLoading: true,
    ordersFBMCompanyLoading: true,
    nextOrdersFBMPage: false,
    ordersFBMForPage: 25,
    ordersFBMFilter: '',

    shippingCompanyData: [],

    ordersFBMPriceLoading: true,
  },

  mutations: {
    setOrdersFBMFilter: (state, payload) => {
      state.ordersFBMFilter = payload
    },

    setOrdersFBMForPage: (state, payload) => {
      state.ordersFBMForPage = payload
    },

    setOrdersFBM: (state, payload) => {
      if(state.nextOrdersFBMPage) {
        state.ordersFBM = state.ordersFBM.concat(payload.ordersFBM)
      } else {
        state.ordersFBM = payload.ordersFBM
      }
    },

    setOrdersFBMCommonList: (state, payload) => {
      state.ordersFBMCommonList = payload.ordersFBMCommonList
    },

    setNextOrdersFBMPage: (state, payload) => {
      state.nextOrdersFBMPage = payload
    },

    setOrdersFBMItem: (state, payload) => {
      state.ordersFBMItem = payload.ordersFBMItem
    },

    setShippingCompanyData: (state, payload) => {
      state.shippingCompanyData = payload.shippingCompanyData
    },

    setOrdersFBMLoadingStart: (state) => {
      state.ordersFBMLoading = true
    },

    setOrdersFBMLoadingEnd: (state) => {
      state.ordersFBMLoading = false
    },

    setOrdersFBMCompanyLoading: (state) => {
      state.ordersFBMCompanyLoading = true
    },

    setOrdersFBMCompanyLoadingEnd: (state) => {
      state.ordersFBMCompanyLoading = false
    },

    changeOrdersFBMBtn: (state) => {
      state.ordersFBMBtn = true
    },
    successOrdersFBMBtn: (state) => {
      state.ordersFBMBtn = false
    },

    setOrdersFBMPriceLoadingStart: (state) => {
      state.ordersFBMPriceLoading = true
    },

    setOrdersFBMPriceLoadingEnd: (state) => {
      state.ordersFBMPriceLoading = false
    },
  },

  getters: {
    getOrdersFBMFilter: state => {
      return state.ordersFBMFilter
    },

    getOrdersFBMForPage: state => {
      return state.ordersFBMForPage
    },

    getOrdersFBM: state => {
      return state.ordersFBM
    },

    getOrdersFBMLoading: state => {
      return state.ordersFBMLoading
    },

    getOrdersFBMCompanyLoading: state => {
      return state.ordersFBMCompanyLoading
    },

    getNextOrdersFBMPage: state => {
      return state.nextOrdersFBMPage
    },

    getOrdersFBMItem: state => {
      return state.ordersFBMItem
    },

    getShippingCompanyData: state => {
      return state.shippingCompanyData
    },

    getOrdersFBMCommonList: state => {
      return state.ordersFBMCommonList
    },

    getOrdersFBMBtn: state => {
      return state.ordersFBMBtn
    },

    getOrdersFBMPriceLoading: state => {
      return state.ordersFBMPriceLoading
    },
  },

  actions: {

    async getOrdersTypes({commit}, filter = '') {
      try {
        return await API.get(`${FBMPrefix}/order-type${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async getOrdersFromOrderType({commit}, id) {
      try {
        return await API.get(`${FBMPrefix}/order-fbm/${id}/list`).then((response) =>{
          // commit('setShippingCompanyByOrder',{setShippingCompanyByOrder: response.data.data.data})
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async getOrderShippingCompany({commit}, data) {
      commit('setOrdersFBMCompanyLoading');
      try {
        return await API.post(`/order-fbm/shipping-company`, data).then((response) =>{
          // console.log(response.data);
          // commit('setShippingCompanyData', {
          //   shippingCompanyData: response.data.data.shipping_companies
          // })
          commit('setOrdersFBMCompanyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMCompanyLoadingEnd');
        return e;
      }
    },

    async sendProductPhotoFBM({commit}, data) {
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/upload-photo`, data).then((response) =>{
          // console.log(response.data);
          // commit('setShippingCompanyData', {
          //   shippingCompanyData: response.data.data.shipping_companies
          // })
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async getOrderShippingPrices({commit}, data) {
      commit('setOrdersFBMLoadingStart');
      commit('changeOrdersFBABtn');
      try {
        return await API.post(`/order-fbm/rate`, data).then((response) =>{
          // console.log(response.data);
          // commit('setShippingCompanyData', {
          //   shippingCompanyData: response.data.data.shipping_companies
          // })
          commit('setOrdersFBMLoadingEnd');
          commit('successOrdersFBABtn');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        commit('successOrdersFBABtn');
        return e;
      }
    },

    async getOrderCalculatedProcessFee({commit}, data) {
      commit('setOrdersFBMLoadingStart');
      try {
        return await API.post(`/order-fbm/process_fee`, data).then((response) =>{
          commit('setOrdersFBMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async getOrdersFBMProductFee({commit}, data) {
      commit('setOrdersFBMLoadingStart');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/product-process-fee`, data).then((response) =>{
          commit('setOrdersFBMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async fetchOrdersFBM({commit, getters}, {filter = '', noCommit = false}) {
      if(!getters.getNextOrdersFBMPage && !noCommit)
        commit('setOrdersFBMLoadingStart')
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}${filter}`).then((response) =>{
          if (!noCommit) {
            commit('setOrdersFBM',{ordersFBM: response.data.data.data})
            commit('setOrdersFBMCommonList',{ordersFBMCommonList: response.data.data})
            commit('setOrdersFBMLoadingEnd');
          }

          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async fetchPublicOrdersFBM({commit, getters}, filter = '') {
      if(!getters.getNextOrdersFBMPage)
        // commit('setOrdersFBMLoadingStart')
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}/public-list${filter}`).then((response) =>{
          // commit('setOrdersFBM',{ordersFBM: response.data.data.data})
          // commit('setOrdersFBMCommonList',{ordersFBMCommonList: response.data.data})
          commit('setOrdersFBMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async fetchOrdersFBMListForConsolidation({commit, getters}, filter = '') {
      if(!getters.getNextOrdersFBMPage)
          // commit('setOrdersFBMLoadingStart')
        try {
          return await API.get(`${FBMPrefix}/${FBMPrefixMain}/consolidation-order-list${filter}`).then((response) =>{
            commit('setOrdersFBMLoadingEnd');
            return response;
          });
        } catch (e) {
          commit('setOrdersFBMLoadingEnd');
          return e;
        }
    },

    async getOrdersFBMEdit({commit}, id) {
      commit('setOrdersFBMLoadingStart');
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}/${id}/edit`).then((response) =>{
          commit('setOrdersFBMItem', {ordersFBMItem: response.data.data})
          commit('setOrdersFBMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async getOrdersFBM({commit}, id) {
      commit('setOrdersFBMLoadingStart');
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}/${id}`).then((response) =>{
          commit('setOrdersFBMItem', {ordersFBMItem: response.data.data})
          commit('setOrdersFBMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async getOrdersFBMCreate({commit}, url = '') {
      commit('setOrdersFBMLoadingStart');
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}/create${url}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    async createOrdersFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async copyOrdersFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/create-copy`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async updateOrdersFBM({commit}, {id, data}) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.put(`${FBMPrefix}/${FBMPrefixMain}/${id}`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async updateOrdersFBMByAdmin({commit}, {id, data}) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.put(`admin${FBMPrefix}/${FBMPrefixMain}/${id}`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async deleteOrdersFBM({commit}, id) {
      try {
        return await API.delete(`${FBMPrefix}/${FBMPrefixMain}/${id}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getIdentifyFBMOrderFromTrn({commit}, {data: data}) {
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/identify-related-instance`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },


    async getFeeOrderFBM({commit}) {
      commit('changeUserManageBtn')
      try {
        // /orders/order-process-fee/fee_order_fbm/list?filter[VipProcessFee]=1
        return await API.get(`/orders/order-process-fee/fee_order_fbm/list?page=1&limit=100`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn')
        return e;
      }
    },


    async getFeeShipStationFBM({commit}) {
      commit('changeUserManageBtn')
      try {
        return await API.get(`/orders/order-process-fee/fee_self_create_label/list?page=1&limit=100`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn')
        return e;
      }
    },

    async getShippingCompanyFBM({commit}, filter = '') {
      commit('changeUserManageBtn')
      try {
        return await API.get(`${FBMPrefix}/shipping-company${filter}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn')
        return e;
      }
    },

    async removeFbmProduct({commit}, {id, data}) {
      commit('changeOrdersFBMBtn')
      console.log(data);
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/${id}/product/remove`, data).then((response) =>{
          commit('successOrdersFBMBtn')
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn')
        return e;
      }
    },


    async getAdminOrdersFBM({commit}, id) {
      commit('setOrdersFBMLoadingStart');
      try {
        return await API.get(`${adminPrefix}${FBMPrefix}/${FBMPrefixMain}/${id}/edit`).then((response) =>{
          console.log(response)
          commit('setOrdersFBMItem', {ordersFBMItem: response.data.data})
          commit('setOrdersFBMLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setOrdersFBMLoadingEnd');
        return e;
      }
    },

    // async updateCustomerInformationOrdersFBM({commit}, {id, data}) {
    //   commit('changeOrdersFBMBtn');
    //   try {
    //     return await API.put(`${adminPrefix}${FBMPrefix}/${FBMPrefixMain}/${id}/customer-information`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },
    //
    // async updateDeliveryAddressOrdersFBM({commit}, {id, data}) {
    //   commit('changeOrdersFBMBtn');
    //   try {
    //     return await API.put(`${adminPrefix}${FBMPrefix}/${FBMPrefixMain}/${id}/edit-address`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },
    //
    // async updateGiftMassageOrdersFBM({commit}, {id, data}) {
    //   commit('changeOrdersFBMBtn');
    //   try {
    //     return await API.put(`${adminPrefix}${FBMPrefix}/${FBMPrefixMain}/${id}/edit-gift`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    async updateUserPlannedDateFedex({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/users/planned-date/order-fbm-fedex-date`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async updateUserPlannedDate({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/users/planned-date`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async updateUserPlannedDateWarehouse({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/users/planned-date/order-fbm-warehouse`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async updateUserPlannedDateConsolidation({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/users/planned-date/order-fbm-consolidation`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getOrderFBMThermalPrint({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.put(`${FBMPrefix}/${FBMPrefixMain}/thermal-marking`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getOrderFBMCreateLabelPdf({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/merge-label`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getOrderFBMCreateLabelBarcodePdf({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/merge-barcodes`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getExportFBM({commit}, {filter , type}) {
      commit('changeOrdersFBMBtn')
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}/export/${type}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('successOrdersFBMBtn')
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('successOrdersFBMBtn')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async buyLabelFBM({commit}, id) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefixMain}/create-label`, {order_fbm_id: id}).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async reloadLabelFBM({commit}, id) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefixMain}/re-create-label`, {order_fbm_id: id}).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async regenerateLabelFBM({commit}, id) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefixMain}/regenerate-label`, {order_fbm_id: id}).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async buyLabelFBMAdmin({commit}, id) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.get(`admin${FBMPrefix}/${FBMPrefixMain}/create-label/${id}`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async prePayFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/prepay`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async prePayAmountFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/prepay-amount`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async generateGiftMessageFile({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/generate-gift-message-file`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async FBMLabelRefund({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefixMain}/refund-label`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getOrdersFBMRoyalMailManifest({commit}) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.get(`/delivery-api/royal-mail-manifest`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async cancelPrepayOrder({commit}, id) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/${id}/cancel`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async updateAdminIdForFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/admin${FBMPrefix}/${FBMPrefixMain}/mass-update-admin-id`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async prepareReportOrdersFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/fbm-on-bin/prepare`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async saveOrdersFBMToOrderBin({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.put(`${FBMPrefix}/fbm-on-bin/save`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getOrdersFBMInOrderBin({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        console.log(data);
        return await API.get(`${FBMPrefix}/fbm-on-bin/scan`, {params: data}).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async resetCurrentOrders({commit}, data) {
      try {
        return await API.post(`${FBMPrefix}/fbm-on-bin/clear`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async stopConsolidationOrdersFBM({commit}, data) {
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/stop-order`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async uploadCustomizationFilesFBM({commit}, data) {
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/mass-upload-customization-document`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
          API.defaults.headers.post['Content-Type'] ='application/json';
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
        API.defaults.headers.post['Content-Type'] ='application/json';
        return e;
      }
    },

    // async uploadEngravingFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/mass-upload-engraving-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async uploadEmbroideryFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/mass-upload-embroidery-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async uploadTShirtPrintingFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/mass-upload-t-shirt-painting-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async uploadPhotoPrintFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/mass-upload-photo-printing-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async uploadLeatherStampFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/mass-upload-leather-stamping-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    async validatingCustomizationFilesFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      API.defaults.headers.post['Accept'] = 'application/json';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`${FBMPrefix}/${FBMPrefixMain}/validate-customization-document`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
          API.defaults.headers.post['Content-Type'] ='application/json';
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
        API.defaults.headers.post['Content-Type'] ='application/json';
        return e;
      }
    },

    // async validatingEngravingFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/validate-engraving-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async validatingEmbroideryFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/validate-embroidery-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async validatingTShirtPrintingFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/validate-t-shirt-painting-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async validatingPhotoPrintFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/validate-photo-printing-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    // async validatingLeatherStampFileFBM({commit}, data) {
    //   commit('changeOrdersFBMBtn');
    //   // API.defaults.headers.post['Accept'] = 'multipart/form-data';
    //   // API.defaults.headers.post['Content-Type'] ='multipart/form-data';
    //   try {
    //     return await API.post(`${FBMPrefix}/${FBMPrefixMain}/validate-leather-stamping-document`, data).then((response) =>{
    //       commit('successOrdersFBMBtn');
    //       return response;
    //     });
    //   } catch (e) {
    //     commit('successOrdersFBMBtn');
    //     return e;
    //   }
    // },

    async validatingUserLabelFileFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/own-label-order-fbm/validate-label`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async uploadUserLabelFileFBM({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`${FBMPrefix}/own-label-order-fbm/upload-label`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getEngravingCalcResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/engraving-calculator/get-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getUVPrintingCalcResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/uv-print-calculator/get-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getEmbroideryCalcResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/embroidery-calculator/get-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getTShirtCalcCreate({commit}) {
      try {
        return await API.get(`/delivery-api/t-shirt-calculator/create?withVariants=1`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getTShirtCalcResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/t-shirt-calculator/get-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getLeatherStampCalcResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/leather-stamp-calculator/get-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getLeatherStampCalcOrderResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/leather-stamp-calculator/get-order-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getPhotoPrintCalcResult({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/photo-print-calculator/get-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getEngravingCalcPriceInOrder({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/engraving-calculator/get-order-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getUVPrintingCalcResultInOrder({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.post(`/delivery-api/uv-print-calculator/get-order-cost`, data).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getScanBarcodeOrdersFBMLabel({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.get(`${FBMPrefix}/${FBMPrefixMain}/tracking-number/${data}/scan`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getFBMNovaPoshtaRegion({commit}, data) {
      if(data.length === 0 || !data) return
      commit('changeOrdersFBMBtn');
      try {
        return await API.get(`/delivery-api/warehouse-list-from-api/find-city/npg/${data}`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getFBMNovaPoshtaDepartment({commit}, data) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.get(`/delivery-api/warehouse-list-from-api/warehouses-by-city/npg/${data}`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

    async getFBMNovaPoshtaAddresses({commit}) {
      commit('changeOrdersFBMBtn');
      try {
        return await API.get(`/express-orders/api-warehouses-list/npg`).then((response) =>{
          commit('successOrdersFBMBtn');
          return response;
        });
      } catch (e) {
        commit('successOrdersFBMBtn');
        return e;
      }
    },

  }
}
