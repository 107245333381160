<template>
  <label :for="'default-input-' + id" class="default-input-wrap"
         :class="{
          active: activeInput || ((value || typeof value === 'number') && value.toString().length > 0),
          'active-placeholder' : (placeholder && placeholder.length > 0),
          'disabled' : disabled == true,
          'default-input-wrap--text-security' : textSecurity && !showSecurity
         }"
  >

    <input
        class="default-input"
        v-bind:class="{'iphone-input': iphoneDetect()}"
        v-on:input="handleInput($event.target.value)"
        :id="'default-input-' + id"
        :type="checkType()"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled === true"
        :maxlength="maxLength"
        :name="name ? name : `name-` + id"
        :readonly="readonly ? 'readonly' : false"
        :pattern="pattern"
        :autocomplete="autocomplete ? autocomplete : autocompleteOff ? 'new-password' : false"
        :x-autocompletetype="autocompletetype"
        v-onlyLetters="!!isOnlyLetters"
        @focus="activeInput = true"
        @blur="blur($event.target.value)"
        @keyup.enter="handleEnter"
        @change="change($event)"
    >
    <span class="default-input-wrap__label"
          v-if="label"
    >
      {{ label }}
    </span>
    <span class="default-input-wrap__pass" @click="showPass" v-if="type === 'password'"></span>
    <span class="default-input-wrap__pass" @click="showSecurityFunc" v-if="textSecurity"></span>
    <span class="default-input-wrap__error error-field" v-if="error && errorTxt">{{errorTxt}}<template v-if="minLengthError"> {{minLengthError}}</template></span>
    <span class="default-input-wrap__caption" v-if="caption">{{caption}}</span>
  </label>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../mixins/mobileFunctions";

  export default {
    name: "DefaultInput",

    mixins: [mixinDetictingMobile],


    data () {
      return {
        id: null,
        isShowPass: false,
        content: this.value,
        activeInput: false,

        showSecurity: false,
      }
    },

    props: [
      'type',
      'label',
      'placeholder',
      'value',
      'error',
      'errorTxt',
      'caption',
      'disabled',
      'maxLength',
      'minLengthError',
      'numbers',
      'name',
      'readonly',
      'autocompleteOff',
      'autocomplete',
      'autocompletetype',
      'pattern',
      'textSecurity',
      'resetAfter',
      'isOnlyLetters',
    ],

    mounted () {
      this.id = this._uid
    },

    watch: {
      value(newVal) {
        this.value = newVal
      }
    },

    methods: {
      change(event){
        this.$emit('change', event.target.value)
        //only use if no value/v-model was passed
        if (this.resetAfter === true) {
          event.target.value = ''
        }
      },

      showPass() {
        this.isShowPass = !this.isShowPass
      },

      showSecurityFunc() {
        this.showSecurity = !this.showSecurity
      },

      checkType() {
        if(this.type === 'password' && this.isShowPass){
          return 'text'
        } else {
          return this.type;
        }
      },

      handleInput (value) {
        this.$emit('input', value)
      },

      blur(value) {
        this.activeInput = false
        this.$emit('blur', value)
      },

      handleEnter() {
        this.$emit('onEnter')
      },

    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";


  .default-input-wrap{
    display: block;
    position: relative;

    &--text-security {
      input {
        -webkit-text-security: disc;
      }
    }

    &.center input{
      text-align: center;
    }

    &.right input{
      text-align: right;
    }

    &.white{
      input{
        background: white;
      }

      /*&.default-input-wrap.active .default-input-wrap__label{*/
        /*background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);*/
      /*}*/
    }

    &.disabled:not(.active){
      .default-input-wrap__label{
        background: transparent!important;
      }
    }

    &.medium{

      input{
        font-weight: 500;
      }
    }

    input{
      font-size: 14px;
      line-height: 16px;
      width: 100%;

      padding-left: 16px;
      padding-right: 16px;
      height: 42px;
      background: $mainBg;
      border: 1px solid $borderBrown;
      box-sizing: border-box;
      border-radius: 5px;

      @include for-550{
        font-size: 16px;
      }

      &::placeholder{
        font-size: 14px;
        /*line-height: 16px;*/
        color: $borderBrown;
        vertical-align: middle;
        line-height: revert; /*fix height placeholder mozilla ff browser*/
      }

      &:disabled{
        background: $brownOpacity02;
        color: $black;
        border-color: transparent;
        opacity: 1;


      }

      &:focus{
        border-color: $accent;
      }
    }

    &__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: $borderBrown;
      transition: 0.15s all;


      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 15px);
      overflow: hidden;
      user-select: none;
      cursor: text;

    }

    &.disabled &__label{
      /*background: transparent !important;*/
      /*background: linear-gradient(0deg, #E9E4DF 0%, #FFFFFF 105.56%) !important;*/

      background: #e9e2da!important;
    }



    &.disabled.active input,
    &.disabled input{
      background: $brownOpacity02 !important;
      border-color: transparent;
      /*color: $borderBrown;*/
      /*color: #675a4a; //fix for mac color*/
      color: #00000059;
      opacity: 1;

      &.iphone-input{
        color: #00000080;
      }
    }

    &__pass{
      display: block;
      width: 25px;
      height: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      background: url("../../../../assets/img/common/pass-show.svg") center center no-repeat;
      cursor: pointer;
    }

    &.active .default-input-wrap__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    &.active-placeholder .default-input-wrap__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    &.active input{
      /*border-color: $accent;*/
    }

    &__error{
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;

      @include for-768{
        bottom: -15px;
      }
    }

    &.ui-no-valid .default-input{
      border: 2px solid $orange;
    }

    &__caption{
      width: 100%;
      text-align: right;
      font-size: 11px;
      line-height: 13px;
      color: $borderBrown;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      margin-bottom: -10px;
    }

    &.scan-ico{

      &:before{
        content: '';
        position: absolute;
        left: 16px;
        top: 14px;
        width: 12px;
        height: 12px;
        background: url("../../../../assets/img/UI-group/scan-ico.svg") center/contain no-repeat;
      }
    }
    &.scan-ico .default-input{
      padding-left: 32px;
    }
    &.scan-ico:not(.active) &__label{
      padding-left: 20px;
    }


  }



</style>
